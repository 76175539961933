import { createGlobalStyle } from "styled-components";

import { device } from "../../utils/breakpoints";

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: auto;
    scrollbar-width: none;
    color: #151515;
  };

  html {
    box-sizing: border-box;
    overflow-x: hidden;
    scrollbar-width: none;
  };

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  };

  body {
    margin: 0;
    font-family: 'Montserrat';
    scrollbar-width: none;
    overscroll-behavior: contain;
    position: relative;

    /* @media ${device.computer}, ${device.tablet}, ${device.tabletLandscape} { */
      overflow-x: hidden;
    /* }; */
  };

  body::-webkit-scrollbar {
    display: none;
  };

  #page {
    @media ${device.mobile} {
      overflow-x: hidden;
    };
  };

  button {
    padding: 0;
    cursor: pointer;
    font-family: 'Montserrat';
  };

  p {
    font-size: 16px;
  };

  ul {
    padding: 0;
    margin: 0;
  };
`;

export default GlobalStyle;