import React from "react";

import GlobalStyle from "../../../assets/styles/globalStyle";

const Layout: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);

export default Layout;