import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    fallbackLng: "pl",
    resources: {
      en: {
        common: require("../assets/locales/en/common.json"),
        contact: require("../assets/locales/en/contact.json"),
        main: require("../assets/locales/en/main.json"),
        rnd: require("../assets/locales/en/rnd.json"),
        career: require("../assets/locales/en/career.json"),
      },
      pl: {
        common: require("../assets/locales/pl/common.json"),
        contact: require("../assets/locales/pl/contact.json"),
        main: require("../assets/locales/pl/main.json"),
        rnd: require("../assets/locales/pl/rnd.json"),
        career: require("../assets/locales/pl/career.json"),
      },
    },
    ns: ["common", "contact", "main", "rnd", "career"],
    defaultNS: "common",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
  });

i18n.languages = ["en", "pl"];

export const getLanguage = () =>
  i18n.languages.includes(i18n.language) ? i18n.language : "en";

export default i18n;
