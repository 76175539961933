export const size = {
  mobileS: "330px",
  mobileM: "375px",
  mobileL: "425px",
  mobile: "767px",
  tablet: "768px",
  tabletMax: "1365px",
  laptop: "1366px",
  laptopL: "1440px",
  desktop: "1920px",
  desktopL: "2560px"
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet}) and (orientation: portrait) and (max-width: ${size.tabletMax})`,
  tabletLandscape: `(min-width: ${size.tablet}) and (orientation: landscape) and (max-width: ${size.tabletMax})`,
  computer: `(min-width: ${size.laptop})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`
};